import JurisdictionAbbreviationMap from '@enview/interface/types/JurisdictionAbbreviationMap';
import { ReactElement, ReactNode, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import trackResourceLinkClick from '../../../analytics/JurisdictionAnalytics';
import { BillAPI, JurisdictionAPI } from '../../../api';
import { State, Thunk } from '../../../dux/@types';
import { checkPermission, getTeamModeTeam } from '../../../dux';
import { g as abilityGlossary } from '../../../config/ability';
import GateTooltip, { GateTooltipFeature } from '../../Shared/Tooltips/TooltipGate';

type JurisdictionResourceInfoProps = {
  jurisAbbr: keyof typeof JurisdictionAbbreviationMap;
};

const JurisdictionResourceInfo = (
  props: JurisdictionResourceInfoProps,
): ReactElement => {
  const { jurisAbbr } = props;
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const {
    data: jurisReferenceInfo,
    isFetching,
    isError,
  } = JurisdictionAPI.endpoints.getReferenceInfo.useQuery(jurisAbbr);
  const { data: trackedBills } = BillAPI.endpoints.getTrackedBills.useQuery(undefined);
  const activeTeam = useSelector(getTeamModeTeam);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);

  const canViewHearings = (): Thunk<boolean> => {
    return dispatch(
      checkPermission(abilityGlossary.VIEW, abilityGlossary.HEARINGS, true),
    );
  };

  const handleHearingsClick = (event: React.MouseEvent) => {
    if (!canViewHearings()) {
      event.preventDefault();
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const billCount = trackedBills?.filter(
    (bill) =>
      bill.session.state.abbreviation.toUpperCase() === jurisAbbr &&
      bill.teams &&
      bill.teams.filter((team) => team.id === activeTeam?.id).length > 0,
  ).length;

  if (isFetching) {
    return <span>{t('jurisdictions.referenceInfo.loadingReferenceLinks')}</span>;
  }
  if (isError) {
    return <span>{t('jurisdictions.referenceInfo.errorLoadingLinks')}</span>;
  }

  const getLink = (category: string): ReactNode => {
    if (jurisReferenceInfo && jurisReferenceInfo?.links?.length > 0) {
      const links = jurisReferenceInfo.links
        .filter((l) => l.category.toLowerCase() === category.toLowerCase())
        .map((link) => (
          <a
            className="reference-link"
            href={link.url}
            key={link.id}
            onClick={() => {
              trackResourceLinkClick(
                jurisReferenceInfo.jurisdictionAbbr,
                link.category,
                link.url,
                'Jurisdiction Details',
                organizationUser,
              );
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            {category === 'legislative'
              ? t('jurisdictions.referenceInfo.legislativeWebsite')
              : t('jurisdictions.referenceInfo.regulationsWebsite')}
          </a>
        ));
      return links?.length > 0 ? (
        <>{links}</>
      ) : (
        t('jurisdictions.referenceInfo.noResourceLinks')
      );
    }
    return <>{t('jurisdictions.referenceInfo.noResourceLinks')}</>;
  };

  const legislatorsLink = t('jurisdictions.urls.legislators', {
    jurisAbbr,
  });
  const committeesLink = t('jurisdictions.urls.committees', {
    jurisAbbr,
  });
  const hearingsLink = t('jurisdictions.urls.hearings', {
    jurisAbbr,
  });
  const sessionsLink = t('jurisdictions.urls.pathPrefix', {
    jurisAbbr,
  });
  const billsLink = t('jurisdictions.urls.bills', {
    jurisAbbr,
  });
  return (
    <div className="overview-info">
      <span className="available-count">
        <h6>{t('jurisdictions.referenceInfo.sessionsAvailable')}</h6>
        <Link to={sessionsLink}>{jurisReferenceInfo?.sessionsCount ?? 0}</Link>
      </span>
      <span className="available-count">
        <h6>{t('jurisdictions.referenceInfo.legislatorsAvailable')}</h6>
        <Link to={legislatorsLink}>{jurisReferenceInfo?.legislatorCount ?? 0}</Link>
      </span>
      <span className="available-count">
        <h6>{t('jurisdictions.referenceInfo.committeesAvailable')}</h6>
        <Link to={committeesLink}>{jurisReferenceInfo?.committeeCount ?? 0}</Link>
      </span>
      <span className="available-count">
        <h6>{t('jurisdictions.referenceInfo.hearingsAvailable')}</h6>
        <Link
          id="hearings-link"
          onClick={handleHearingsClick}
          to={canViewHearings() ? hearingsLink : '#'}
        >
          {jurisReferenceInfo?.hearingsCount ?? 0}
        </Link>
      </span>
      <GateTooltip
        accountType="pro"
        anchorId="hearings-link"
        customContent={
          <Trans
            components={{
              hearingsLink: <a href={t('urls.hearings')} target="_blank" />,
              gate: <p className="tooltip-header" />,
            }}
            i18nKey="featureGating.tooltipText.newHearingsPage"
          />
        }
        featureName={GateTooltipFeature.HearingsPage}
        isOpen={showTooltip}
        place="bottom"
        setIsOpen={setShowTooltip}
      />
      <span className="available-count">
        <h6>{t('jurisdictions.referenceInfo.trackedBills')}</h6>
        <Link to={billsLink}>{billCount}</Link>
      </span>
      <span>
        <h6 className="resource-title">
          {t('jurisdictions.referenceInfo.legislativeTitle')}
        </h6>
        {getLink('legislative')}
      </span>
      <span>
        <h6 className="resource-title">
          {t('jurisdictions.referenceInfo.regulationsTitle')}
        </h6>
        {getLink('regulatory')}
      </span>
    </div>
  );
};
export default JurisdictionResourceInfo;
